import React from 'react'

const About = () => {
  return (
    <div
    name="about"
    className="w-full h-screen bg-gradient-to-b from-gray-800 
    to-black text-white">

        <div className='max-w-screen-lg p-4 mx-auto flex flex-col
         justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>
                  About
                </p>
            </div>
            <p className='text-xl mt-20 '>
            <br/> I am a passionate Full stack developer driven by a continuous desire to learn, solve
            problems and innovate. I am excited to take on new challenges,
            leverage my existing skills, and contribute to the success of a
            forward-thinking organization that values collaboration, creativity.
            

            </p>
        </div>
    </div>
  )
}

export default About